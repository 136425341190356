<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/openingbalance`"
                :columns="columns"
                routerpath="/master/account/openingbalance/addopeningbalance"
                :formOptions="formOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.params.id ? 'Edit Opening Balance' : 'Add Opening Balance'
        }`,
        // submitButtonName: "Add Plot Master",
        submitRouterPath: '/master/crm',
        gridForm: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/openingbalance`,
        inputFields: [
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Plot No',
            name: 'plotno',
            value: '',
            type: 'text',
            placeholder: 'Enter Plot No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Plot Name/Customer Name',
            name: 'plotname',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Plot No',
            name: 'plotno',
            value: '',
            type: 'text',
            placeholder: 'Enter Plot No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Plot Area Yard',
            name: 'plotareayard',
            value: '',
            type: 'text',
            placeholder: 'Enter Plot Area Yard',
            class: 'col-md-6',
            required: true
          },
          {
            label: '.Rate Per(Sq. Yd)',
            name: 'ratepersqyd',
            value: '',
            type: 'text',
            placeholder: 'Enter .Rate Per(Sq. Yd)',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Plot value',
            name: 'plotvalue',
            value: '',
            type: 'number',
            placeholder: 'Enter Plot value',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Sq meters',
            name: 'sqmeters',
            value: '',
            type: 'number',
            placeholder: 'Enter Sq meters',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Plot Area(Sq. Mtr) Carpet',
            name: 'plotarea',
            value: '',
            type: 'number',
            placeholder: 'Enter Plot Area(Sq. Mtr) Carpet',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Sq. Feet',
            name: 'sqfeet',
            value: '',
            type: 'number',
            placeholder: 'Enter Sq. Feet',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Project',
            name: 'project',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'project',
            url: 'project',
            required: true,
            responseValue: 'id',
            options: []
          },
          {
            label: 'New Survey No',
            name: 'newsurveyno',
            value: '',
            type: 'number',
            placeholder: 'Enter New Survey No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Old Survey No',
            name: 'oldsurveyno',
            value: '',
            type: 'number',
            placeholder: 'Enter Old Survey No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Status',
            name: 'status',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'status',
            url: 'status',
            required: true,
            responseValue: 'id',
            options: []
          },
          {
            label: 'Towards the North',
            name: 'towardsthenorth',
            value: '',
            type: 'text',
            placeholder: 'Enter Towards the North',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Towards the South',
            name: 'towardsthesouth',
            value: '',
            type: 'text',
            placeholder: 'Enter Towards the South',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Towards the East',
            name: 'towardstheeast',
            value: '',
            type: 'text',
            placeholder: 'Enter Towards the East',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Towards the West',
            name: 'towardsthewest',
            value: '',
            type: 'text',
            placeholder: 'Enter Towards the West',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Registration No',
            name: 'registrationno',
            value: '',
            type: 'text',
            placeholder: 'Enter Registration No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Registration Date',
            name: 'registrationdate',
            value: new Date(),
            type: 'datetime',
            placeholder: 'Enter Registration date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Na Order No',
            name: 'naorderno',
            value: '',
            type: 'number',
            placeholder: 'Enter Na Order No',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'text',
            placeholder: 'Enter Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Country',
            name: 'country',
            value: '',
            type: 'text',
            placeholder: 'Enter Country',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'number',
            placeholder: 'Enter Pincode',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Document',
            name: 'document',
            value: '',
            type: 'fileinput',
            placeholder: 'select Document',
            class: 'col-md-6',
            required: true
          }
        ]
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search name'
          }
        },
        {
          label: 'Mobile',
          field: 'mobile',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Mobile'
          }
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email'
          }
        },
        {
          label: 'Address',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Address'
          }
        },
        {
          label: 'Area',
          field: 'area',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Area'
          }
        },
        {
          label: 'City',
          field: 'city',
          filterOptions: {
            enabled: true,
            placeholder: 'Search City'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
